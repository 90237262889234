import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import ImageIntervention from "../components/imageIntervention"
import ImageClinique from "../components/imageClinique"
import ASavoirIntervention from "../components/aSavoirIntervention"
import Share from "../components/share"
import { css } from "@emotion/react"
import { Helmet } from "react-helmet"

import SEO from "../components/seo"

const InterventionPage = () => (
  <Layout pathEn="/en/clinic/">
    <SEO
      title="Hospitalisation pour une opération de chirurgie de la main, du coude et
      de l’épaule"
      description="Opérations de chirurgie de la main, du coude, de l’épaule et de la clavicule pratiqués par le Dr Roure à la Clinique du Sport de Paris (5eme arrondissement)."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="en"
        href={`https://www.docteurphilipperoure.com/en/clinic/`}
      />
    </Helmet>
    <ImageIntervention />
    <WrapperTitle>
      <Breadcrumb>
        <Link to="/">Accueil </Link>
      </Breadcrumb>
      <H1>
        Hospitalisation pour une opération de chirurgie de la main, du coude et
        de l’épaule
      </H1>
      <p className="intro">
        Les interventions de chirurgie de la main, du coude, de l’épaule et de
        la clavicule pratiqué par le Dr&nbsp;Philippe Roure ont lieu à la
        Clinique du Sport de Paris (5<sup>eme</sup> arrondissement). Cet
        établissement est hyperspécialisé dans la chirurgie orthopédique et la
        chirurgie arthroscopique et propose un plateau technique
        particulièrement adapté aux intervention de chirurgie de la main, du
        coude et de l'épaule.
      </p>
    </WrapperTitle>
    <Wrapper>
      <Row>
        <Col1>
          <H2>
            Clinique du <span>Sport</span>
          </H2>
          <p>
            Les interventions ont lieu &agrave; la{" "}
            <strong>
              <a
                href="http://clinique-du-sport-paris.ramsaygds.fr/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Clinique du Sport
              </a>
            </strong>{" "}
            (36 Boulevard Saint Marcel, 75005 Paris). Cet &eacute;tablissement
            hypersp&eacute;cialis&eacute; dans la{" "}
            <strong>chirurgie orthop&eacute;dique</strong> et la chirurgie
            arthroscopique a une longue exp&eacute;rience de la prise en charge
            des sportifs.&nbsp;
          </p>
          <p>
            Il offre un <b>plateau technique complet</b> et
            particuli&egrave;rement adapt&eacute; aux interventions de chirurgie
            orthopédiques.
          </p>
          <p>
            Appartenant au groupe{" "}
            <strong>
              <a
                href="https://ramsaygds.fr/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Ramsay-G&eacute;n&eacute;rale de Sant&eacute;
              </a>
            </strong>
            , cette clinique r&eacute;pond aux crit&egrave;res de
            s&eacute;curit&eacute; les plus stricts.
          </p>
        </Col1>
        <Col2>
          <ImageClinique />
        </Col2>
      </Row>

      <Row>
        <Col3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10504.041204804107!2d2.3579524!3d48.8389422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ed01a548cf442fd!2sClinique+du+Sport+-+Ramsay+G%C3%A9n%C3%A9rale+de+Sant%C3%A9!5e0!3m2!1sfr!2sus!4v1558477994570!5m2!1sfr!2sus"
            width="100%"
            height="295"
            frameborder="0"
            title="Plan du cabinet du Dr Philippe Roure à Paris 7"
            allowfullscreen
          />
        </Col3>
        <Col4>
          <H2>
            Types d'<span>hospitalisation</span>
          </H2>
          <p>
            En fonction de votre situation, les interventions peuvent se
            d&eacute;rouler dans la journ&eacute;e (<strong>ambulatoire</strong>
            ) ou n&eacute;cessiter une hospitalisation de{" "}
            <strong>quelques jours</strong>.
          </p>
          <p>
            Plusieurs cat&eacute;gories de chambres, de la chambre simple
            &agrave; la suite avec service h&ocirc;telier, et formules
            d'hospitalisation vous seront{" "}
            <strong>propos&eacute;es et expliqu&eacute;es</strong> par mes
            assistantes.
          </p>
          <p>
            Mon assistante <Link to="/equipe/">Paula</Link> est sur place pour
            vous assister personnellement durant votre s&eacute;jour et dans vos
            d&eacute;marches aupr&egrave;s de la clinique. Vous serez accueilli
            à la clinique par <Link to="/equipe/">Cyril</Link>, qui est
            infirmier dipl&ocirc;m&eacute; de bloc op&eacute;ratoire et mon
            assistant op&eacute;ratoire. Ce sera également lui qui vous
            accueillera en salle d&rsquo;op&eacute;ration.
          </p>
        </Col4>
      </Row>

      <H2
        css={css`
          margin-bottom: 25px;
        `}
      >
        <span>À savoir</span> dans le cadre d'une intervention
        chirurgicale&nbsp;
      </H2>

      <ASavoirIntervention />
      <Share
        slug="https://www.docteurphilipperoure.com/hospitalisation/"
        title="Intervention de chirurgie orthopédique - Dr Philippe Roure"
      />
    </Wrapper>
  </Layout>
)

export default InterventionPage

const WrapperTitle = styled.div`
  background-color: white;
  width: 78%;
  margin: 0 auto;
  border-radius: 7px;
  padding: 1.5em 2em 0.9em;
  margin-bottom: 45px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
    padding: 1em 1em 0.5em;
    margin-bottom: 25px;
    border-radius: 0 0 7px 7px;
  }
`
const Breadcrumb = styled.div`
  color: #e6ae48;
  text-align: center;
  font-size: 0.9em;
  line-height: 1em;
  font-weight: 400;
  a {
    color: #919090;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  font-size: 2.1em;
  @media (max-width: 678px) {
    font-size: 1.82em;
    line-height: 1.1em;
  }
`

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 2.25em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    border-radius: 7px;
    padding: 1em;
  }
`

const H2 = styled.h2`
  font-weight: 400;
  line-height: 1.38em;
  font-size: 1.9em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 1.7em;
    line-height: 1.3em;
  }
`

const Row = styled.div`
  display: flex;
  margin: 15px 0 60px;
  clear: both;
  @media (max-width: 678px) {
    flex-wrap: wrap;
    margin: 0px 0 30px;
  }
`

const Col1 = styled.div`
  flex: 52%;
  padding-right: 10px;
  @media (max-width: 678px) {
    padding: 12px 0 0;
    order: 2;
    flex-basis: 100%;
  }
`

const Col2 = styled.div`
  flex: 48%;
  padding-left: 10px;
  @media (max-width: 678px) {
    padding: 0;
    order: 1;
    flex-basis: 100%;
  }
`

const Col3 = styled.div`
  flex: 39%;
  @media (max-width: 678px) {
    flex-basis: 100%;
    padding: 0;
  }
`

const Col4 = styled.div`
  flex: 61%;
  padding-left: 25px;
  @media (max-width: 678px) {
    flex-basis: 100%;
    padding: 12px 0 0;
  }
`
