import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allMarkdownRemark(
          sort: { fields: [frontmatter___title], order: ASC }
          filter: { frontmatter: { tags: { eq: "hospitalisation" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                excerpt
                image {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridWrapper>
        {data.allMarkdownRemark.edges
          .filter(edges => edges.node.fields.slug !== "/chirurgie-du-sport/")
          .map(({ node }) => (
            <GridItem key={node.fields.slug}>
              <Link
                to={node.fields.slug}
                css={css`
                  text-decoration: none;
                `}
              >
                <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                <H3>{node.frontmatter.title}</H3>
                <Description>{node.frontmatter.excerpt}</Description>
                <Suite>> Lire la suite</Suite>
              </Link>
            </GridItem>
          ))}
      </GridWrapper>
    )}
  />
)

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-template-row: 1fr 1fr 1fr;
  grid-gap: 2%;
  margin-bottom: 10px;
  @media (max-width: 678px) {
    display: block;
  }
`

const GridItem = styled.div`
  margin: 0 0 0.5em;
  padding-bottom: 0.3em;
  transition: all 0.2s;
  .gatsby-image-wrapper img {
    transition: all 0.7s !important;
    transform: scale(1) !important;
  }
  &:hover {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.18);
    transition: all 0.2s;
    .gatsby-image-wrapper img {
      transition: all 0.7s !important;
      transform: scale(1.05) !important;
    }
  }
  @media (max-width: 678px) {
    &:hover {
      box-shadow: none;
      .gatsby-image-wrapper img {
        transform: scale(1) !important;
      }
    }
  }
`

const H3 = styled.h3`
  font-size: 1.25em;
  line-height: 1.25em;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin: 0;
  padding: 10px 12px 7px;
  @media (max-width: 678px) {
    padding: 10px 3px 7px;
  }
`
const Description = styled.div`
  padding: 0px 14px 15px;
  font-size: 0.93em;
  line-height: 1.55em;
  @media (max-width: 678px) {
    padding: 0 3px 0;
  }
`

const Suite = styled.p`
  display: none;
  font-size: 13px;
  opacity: 0.82;
  text-align: right;
  margin-right: 20px;
  font-weight: 400;
  margin-top: 0;

  :hover {
    color: #d69c31;
  }
  @media (max-width: 1000px) {
    display: block;
  }
`
