import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageInterventionQuery {
        file(relativePath: { eq: "intervention.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Interventions de chirurgie du bras, de la main, de l'épaule et du coude à Paris."
        css={css`
          width: 100%;
          transform: translateY(85px);
          z-index: -1;
          margin-top: -85px;
          @media (max-width: 1024px) {
            transform: none;
            margin-top: 0;
            border-radius: 7px 7px 0 0;
          }
        `}
      />
    )}
  />
)
